import { useNavigation } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';

import { cn } from '~/lib/styles';

export function GlobalLoadingBar() {
  const navigation = useNavigation();
  const active = navigation.state !== 'idle';

  const ref = useRef<HTMLDivElement>(null);
  const [animationComplete, setAnimationComplete] = useState(true);

  useEffect(() => {
    if (!ref.current) return;
    if (active) setAnimationComplete(false);

    Promise.allSettled(
      ref.current.getAnimations().map(({ finished }) => finished)
    )
      .then(() => {
        if (!active) {
          setAnimationComplete(true);
        }
      })
      .catch(() => {});
  }, [active]);

  return (
    <div
      aria-hidden={!active}
      className="fixed inset-x-0 top-0 z-50 h-0.5 animate-pulse"
    >
      <div
        ref={ref}
        className={cn(
          'h-full origin-left rounded-r-full bg-gradient-to-r from-brand-secondary to-regal-blue-500 opacity-75 transition-all duration-300 ease-out',
          {
            'scale-x-0 opacity-0 duration-100':
              navigation.state === 'idle' && animationComplete,
            'scale-x-[25%]': navigation.state === 'submitting',
            'scale-x-[75%]': navigation.state === 'loading',
            'scale-x-100': navigation.state === 'idle' && !animationComplete
          }
        )}
      />
    </div>
  );
}
